<template>
  <section id="apercu">
    <b-row
      v-if="ready"
    >
      <b-col
        v-for="design in designs"
        :key="design.id"
        sm="12"
        md="4"
        lg="4"
      >
        <b-card class="text-left" style="border: 0.5px solid grey; -moz-box-shadow: 3px 3px 5px 6px #ccc; -webkit-box-shadow: 3px 3px 5px 6px #ccc; box-shadow: 3px 3px 5px 6px #ccc;">
          <h5 class="text-center">#{{ design.id }}. {{ design.name }}</h5>
          <hr/>
          <div v-if="design.format == 'image' || design.format == 'video'">
            <div
              class="d-flex justify-content-start align-items-center mb-1"
            >
              <!-- avatar -->
              <b-avatar
                icon="FacebookIcon"
                size="25"
                class="mr-1"
                variant="light-info"
              >
                <feather-icon
                  size="25"
                  icon="FacebookIcon"
                />
              </b-avatar>
              <!--/ avatar -->
              <div class="profile-user-info">
                <h6 class="mb-0">
                  {{ design.visuels[0].title }}
                </h6>
                <small class="text-muted">12 Dec 2018 à 13:16</small>
              </div>
            </div>
            <b-card-text>
              {{ design.visuels[0].text }}
            </b-card-text>

            <!-- post img -->
            <b-img
              v-if="design.design_format == 'image'"
              thumbnail
              fluid
              rounded
              class="mb-25"
              :src="`https://app2.oscar-black.com/uploads/visuels/${design.visuels[0].file}`"
            />
            <!--/ post img -->
            <!-- post video -->
            <b-embed
              v-else
              type="iframe"
              :src="`https://app2.oscar-black.com/uploads/visuels/${design.visuels[0].file}`"
              allowfullscreen
              class="rounded mb-50"
            />
            <!--/ post video -->
            <b-card-text class="mt-2">
              {{ design.visuels[0].description }}
              <p>Prix : {{ design.visuels[0].pricing }} €</p>
              <p>URL de redirection : {{ design.visuels[0].redirection }}</p>
            </b-card-text>

            <!-- likes comments  share-->
            <b-row class="pb-50 mt-50">
              <b-col
                sm="8"
                class="d-flex justify-content-between justify-content-sm-start mb-2"
              >
                <b-link class="d-flex align-items-center text-muted text-nowrap">
                  <feather-icon
                    icon="HeartIcon"
                    class="mr-50 profile-likes"
                    size="18"
                  />
                  <span>1,2K</span>
                </b-link>
                <div class="d-flex align-item-center">
                  <b-avatar-group
                    size="12"
                    class="ml-1"
                  >
                    <b-avatar
                      v-for="(avatarData,i) in likedUsers"
                      :key="i"
                      v-b-tooltip.hover.bottom="avatarData.username"
                      class="pull-up"
                      size="25"
                      :src="avatarData.avatar"
                    />
                  </b-avatar-group>
                  <b-link class="text-muted text-nowrap mt-50 ml-50">
                    +157 autres
                  </b-link>
                </div>
              </b-col>
              <b-col
                sm="4"
                class="d-flex justify-content-between justify-content-sm-end align-items-center mb-2"
              >
                <b-link class="text-body text-nowrap">
                  <feather-icon
                    icon="MessageSquareIcon"
                    size="18"
                    class="profile-icon mr-50"
                  />
                  <span class="text-muted mr-1">1,2K</span>
                </b-link>
                <b-link class="text-body text-nowrap">
                  <feather-icon
                    icon="Share2Icon"
                    size="18"
                    class="profile-icon mr-50"
                  />
                  <span class="text-muted">1,2K</span>
                </b-link>
              </b-col>
            </b-row>
            <!--/ likes comments  share-->
          </div>

          <div v-else>
            <b-carousel
              controls
              indicators
            >
              <b-carousel-slide
                v-for="visuel in design.visuels"
                :key="visuel.id"
              >
                <template
                  #img
                >
                  <div
                    class="d-flex justify-content-start align-items-center mb-1"
                  >
                    <!-- avatar -->
                    <b-avatar
                      icon="FacebookIcon"
                      size="25"
                      class="mr-1"
                      variant="light-info"
                    >
                      <feather-icon
                        size="25"
                        icon="FacebookIcon"
                      />
                    </b-avatar>
                    <!--/ avatar -->
                    <div class="profile-user-info">
                      <h6 class="mb-0">
                        {{ visuel.title }}
                      </h6>
                      <small class="text-muted">12 Dec 2018 à 13:16</small>
                    </div>
                  </div>
                  <b-card-text>
                    {{ visuel.text }}
                  </b-card-text>

                  <!-- post img -->
                  <b-img
                    v-if="visuel.file.substr(visuel.file.lastIndexOf('.') + 1) != 'mp4'"
                    thumbnail
                    fluid
                    rounded
                    class="mb-25"
                    :src="`https://app2.oscar-black.com/uploads/visuels/${visuel.file}`"
                  />
                  <!--/ post img -->
                  <!-- post video -->
                  <b-embed
                    v-else
                    type="iframe"
                    :src="`https://app2.oscar-black.com/uploads/visuels/${visuel.file}`"
                    allowfullscreen
                    class="rounded mb-50"
                  />
                  <!--/ post video -->
                  <b-card-text class="mt-2">
                    <p>{{ visuel.description }}</p>
                    <p>Prix : {{ visuel.pricing }} €</p>
                    <p>URL de redirection : {{ visuel.redirection }}</p>
                  </b-card-text>

                  <!-- likes comments  share-->
                  <b-row class="pb-50 mt-50">
                    <b-col
                      sm="8"
                      class="d-flex justify-content-between justify-content-sm-start mb-2"
                    >
                      <b-link class="d-flex align-items-center text-muted text-nowrap">
                        <feather-icon
                          icon="HeartIcon"
                          class="mr-50 profile-likes"
                          size="18"
                        />
                        <span>1,2K</span>
                      </b-link>
                      <div class="d-flex align-item-center">
                        <b-avatar-group
                          size="12"
                          class="ml-1"
                        >
                          <b-avatar
                            v-for="(avatarData,i) in likedUsers"
                            :key="i"
                            v-b-tooltip.hover.bottom="avatarData.username"
                            class="pull-up"
                            size="25"
                            :src="avatarData.avatar"
                          />
                        </b-avatar-group>
                        <b-link class="text-muted text-nowrap mt-50 ml-50">
                          +157 autres
                        </b-link>
                      </div>
                    </b-col>
                    <b-col
                      sm="4"
                      class="d-flex justify-content-between justify-content-sm-end align-items-center mb-2"
                    >
                      <b-link class="text-body text-nowrap">
                        <feather-icon
                          icon="MessageSquareIcon"
                          size="18"
                          class="profile-icon mr-50"
                        />
                        <span class="text-muted mr-1">1,2K</span>
                      </b-link>
                      <b-link class="text-body text-nowrap">
                        <feather-icon
                          icon="Share2Icon"
                          size="18"
                          class="profile-icon mr-50"
                        />
                        <span class="text-muted">1,2K</span>
                      </b-link>
                    </b-col>
                  </b-row>
                </template>
              </b-carousel-slide>
            </b-carousel>
            <!--/ likes comments  share-->
          </div>
          <b-button
            v-if="design.design_cta == 'postuler_maintenant'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
          >
            Postuler maintenant
          </b-button>
          <b-button
            v-if="design.design_cta == 'reserver'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
          >
            Réserver
          </b-button>
          <b-button
            v-if="design.design_cta == 'nous_contacter'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width:50%;margin-right:auto;margin-left:auto;"
          >
            Nous contacter
          </b-button>
          <b-button
            v-if="design.design_cta == 'telecharger'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width:50%;margin-right:auto;margin-left:auto;"
          >
            Télécharger
          </b-button>
          <b-button
            v-if="design.design_cta == 'profiter_de_l_offre'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width:50%;margin-right:auto;margin-left:auto;"
          >
            Profiter de l'offre
          </b-button>
          <b-button
            v-if="design.design_cta == 'obtenir_un_devis'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width:50%;margin-right:auto;margin-left:auto;"
          >
            Obtenir un devis
          </b-button>
          <b-button
            v-if="design.design_cta == 'obtenir_les_horaires'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width:50%;margin-right:auto;margin-left:auto;"
          >
            Obtenir les horaires
          </b-button>
          <b-button
            v-if="design.design_cta == 'obtenir_l_acces'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width:50%;margin-right:auto;margin-left:auto;"
          >
            Obtenir l'accès
          </b-button>
          <b-button
            v-if="design.design_cta == 'demander_l_horaire'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width:50%;margin-right:auto;margin-left:auto;"
          >
            Demander l'horaire
          </b-button>
          <b-button
            v-if="design.design_cta == 'voir_le_menu'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width:50%;margin-right:auto;margin-left:auto;"
          >
            Voir le menu
          </b-button>
          <b-button
            v-if="design.design_cta == 'acheter'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width:50%;margin-right:auto;margin-left:auto;"
          >
            Acheter
          </b-button>
          <b-button
            v-if="design.design_cta == 's_inscrire'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width:50%;margin-right:auto;margin-left:auto;"
          >
            S'inscrire
          </b-button>
          <b-button
            v-if="design.design_cta == 's_abonner'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width:50%;margin-right:auto;margin-left:auto;"
          >
            S'abonner
          </b-button>
          <b-button
            v-if="design.design_cta == 'voir_d_autres_videos'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width:50%;margin-right:auto;margin-left:auto;"
          >
            Voir d'autres vidéos
          </b-button>
          <b-button
            v-if="design.design_cta == 'envoyer_un_message_whatsapp'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width:50%;margin-right:auto;margin-left:auto;"
          >
            Envoyer un message Whatsapp
          </b-button>
          <b-button
            v-if="design.design_cta == 'en_savoir_plus'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width:50%;margin-right:auto;margin-left:auto;"
          >
            En savoir plus
          </b-button>
          <hr/>
          <b-card-text
            class="mt-2"
          >
            <small class="text-muted w-100">Créé le {{ (new Date(design.created*1000)).getDate() }}/{{ (new Date(design.created*1000)).getMonth()+1 }}/{{ (new Date(design.created*1000)).getFullYear() }} <br/></small>
            <small class="text-muted w-100">Dernière modification le {{ (new Date(design.modified*1000)).getDate() }}/{{ (new Date(design.modified*1000)).getMonth()+1 }}/{{ (new Date(design.modified*1000)).getFullYear() }} </small>
          </b-card-text>
          <b-row>
            <b-col md="6">
              <b-button
                @click="editEnCours(design.id)"
                v-b-toggle.sidebar-right
                variant="primary"
                class="btn-icon"
                style="width:100%"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </b-col>
            <b-col md="6">
              <b-button
                variant="danger"
                class="btn-icon"
                @click="confirmDelete(design.id)"
                style="width:100%"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-sidebar
      id="sidebar-right"
      bg-variant="white"
      right
      backdrop
      shadow
    >
      <form-wizard-numberr v-if="edit !== null" :id="edit" @designss="designss" />
    </b-sidebar>
    <b-row>
      <b-col cols="12">
        <form-wizard-number @designss="designss" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import axios from '@axios'
import {
  BCard, BCardText, BRow, BCol, BButton, BImg, BSidebar, VBToggle, BCarousel, BCarouselSlide, BEmbed, BAvatar, BAvatarGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FormWizardNumber from './FormWizardNumber.vue'

/* eslint-disable vue/no-unused-vars */
import FormWizardNumberr from './FormWizardNumberr.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BImg,
    BSidebar,
    /* eslint-disable vue/no-unused-components */
    VBToggle,
    BCarousel,
    BCarouselSlide,
    BEmbed,
    FormWizardNumber,
    FormWizardNumberr,
    BAvatar,
    BAvatarGroup,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      designs: [],
      ready: false,
      edit: null,
      likedUsers: [
        /* eslint-disable global-require */
        { avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'), username: 'Trine Lynes' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-2.jpg'), username: 'Lilian Nenes' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-3.jpg'), username: 'Alberto Glotzbach' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-5.jpg'), username: 'George Nordic' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-4.jpg'), username: 'Vinnie Mostowy' },
      ],
    }
  },
  methods: {
    editEnCours(val) {
      this.edit = val
    },
    designss(val) {
      this.designs = val
    },
    confirmDelete(id) {
      this.$swal({
        title: 'Êtes-vous sûr ?',
        text: 'Il sera impossible de revenir en arrière...',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, supprimer ce design',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const req = {
            _id: id,
          }
          axios
            .delete('https://app2.oscar-black.com/api/design', { data: req })
            .then(response => {
              this.designs = response.data
              this.$swal({
                icon: 'success',
                title: 'Supprimé !',
                text: 'Votre design a bien été supprimé.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              this.$swal({
                icon: 'error',
                title: 'Oops, pas supprimé !',
                text: error.response.data,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Annulé',
            text: 'Votre design est en sécurité :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
  created() {
    axios
      .get('https://app2.oscar-black.com/api/design')
      .then(response => {
        this.designs = response.data
        setTimeout(() => { this.ready = true }, 500)
      })
      .catch(() => {})
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

  #sidebar-right {
    width: 550px !important;
  }
</style>
