<template>
  <div>
    <form-wizard
      color="#29235C"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >

      <!-- accoint details tab -->
      <tab-content
        title="Format"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Design name"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="johndoe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Format"
                label-for="format"
              >
                <b-form-radio-group
                  id="format"
                  v-model="format"
                  :options="formatOptions"
                  class="demo-inline-spacing"
                  name="radio-validation"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- personal details tab -->
      <tab-content
        title="Creation"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5
                v-if="format=='carrousel'"
                class="mb-0"
              >
                Your ads details
              </h5>
              <h5
                v-else
                class="mb-0"
              >
                Your ad details
              </h5>
            </b-col>
          </b-row>
          <div
            v-if="format == 'carrousel'"
          >
            <div
              v-for="(item, index) in items"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Ad title"
                    label-for="adTitle"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Ad title"
                      rules="required"
                    >
                      <b-form-input
                        id="adTitle"
                        v-model="item.adTitle"
                        placeholder="John"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Image / Video"
                    label-for="last-name"
                  >
                    <b-form-file
                      id="extension"
                      v-model="item.file"
                      accept=".jpg, .png, .gif"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group
                    label="Price (not mandatory)"
                    label-for="price"
                  >
                    <b-input-group
                      append="€"
                    >
                      <b-form-input
                        id="price"
                        v-model="item.pricing"
                        placeholder="100"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Ad Text"
                    label-for="adText"
                  >
                    <b-form-textarea
                      id="adText"
                      v-model="item.adText"
                      rows="3"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Ad description (not mandatory)"
                    label-for="adDescription"
                  >
                    <b-form-textarea
                      id="adDescription"
                      v-model="item.adDescription"
                      rows="3"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Redirection URL"
                    label-for="redirectionUrl"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Redirection URL"
                      rules="required"
                    >
                      <b-form-input
                        id="redirectionUrl"
                        v-model="item.redirectionUrl"
                        placeholder="John"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                    <span>Delete</span>
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </div>
          </div>
          <div v-else>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Ad title"
                  label-for="adTitle"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <b-form-input
                      id="adTitle"
                      v-model="adTitle"
                      placeholder="John"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  v-if="format == 'image'"
                  label="Image"
                  label-for="last-name"
                >
                  <b-form-file
                    id="extension"
                    v-model="file"
                    accept=".jpg, .png, .gif"
                  />
                </b-form-group>
                <b-form-group
                  v-else-if="format == 'video'"
                  label="Video"
                  label-for="last-name"
                >
                  <b-form-file
                    id="extension"
                    v-model="file"
                    accept=".jpg, .png, .gif"
                  />
                </b-form-group>
                <b-form-group
                  v-else
                  label="Image / Video"
                  label-for="last-name"
                >
                  <b-form-file
                    id="extension"
                    v-model="file"
                    accept=".jpg, .png, .gif"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Price (not mandatory)"
                  label-for="price"
                >
                  <b-input-group
                      append="€"
                    >
                      <b-form-input
                        id="price"
                        v-model="price"
                        placeholder="100" />
                    </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Ad Text"
                  label-for="adText"
                >
                  <b-form-textarea
                    id="adText"
                    v-model="adText"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Ad description (not mandatory)"
                  label-for="adDescription"
                >
                  <b-form-textarea
                    id="adDescription"
                    v-model="adDescription"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Redirection URL"
                  label-for="redirectionUrl"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Redirection URL"
                    rules="required"
                  >
                    <b-form-input
                      id="redirectionUrl"
                      v-model="redirectionUrl"
                      placeholder="John"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </div>
          <b-button class="mt-1 mb-2" v-if="format=='carrousel'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="repeateAgain"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>Add New</span>
          </b-button>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Call to action (CTA) button"
                label-for="CTAbutton"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Redirection URL"
                  rules="required"
                >
                  <b-form-select
                    id="CTAbutton"
                    v-model="ctaButton"
                    :options="CTAoption"
                    class="demo-inline-spacing"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>

  </div>
</template>

<script>
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormSelect,
  BFormFile,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormSelect,
    BFormFile,
    BFormTextarea,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    id: Number,
  },
  data() {
    return {
      items: [],
      ctaButton: '',
      redirectionUrl: '',
      file: '',
      price: '',
      selectedContry: '',
      selectedLanguage: '',
      name: '',
      adTitle: '',
      adDescription: '',
      adText: '',
      required,
      format: 'image',
      formatOptions: [
        { text: 'Image', value: 'image' },
        { text: 'Video', value: 'video' },
        { text: 'Carrousel', value: 'carrousel' },
      ],
      CTAoption: [
        { value: 'postuler_maintenant', text: 'Postuler maintenant' },
        { value: 'reserver', text: 'Réserver' },
        { value: 'nous_contacter', text: 'Nous contacter' },
        { value: 'telecharger', text: 'Télécharger' },
        { value: 'profiter_de_l_offre', text: 'Profiter de l\'offre' },
        { value: 'obtenir_un_devis', text: 'Obtenir un devis' },
        { value: 'obtenir_les_horaires', text: 'Obtenir les horaires' },
        { value: 'obtenir_l_acces', text: 'Obtenir l\'accès' },
        { value: 'demander_l_horaire', text: 'Demander l\'horaire' },
        { value: 'voir_le_menu', text: 'Voir le menu' },
        { value: 'acheter', text: 'Acheter' },
        { value: 's_inscrire', text: 'S\'inscrire' },
        { value: 's_abonner', text: 'S\'abonner' },
        { value: 'voir_d_autres_videos', text: 'Voir d\'autres vidéos' },
        { value: 'envoyer_un_message_whatsapp', text: 'Envoyer un message WhatsApp' },
        { value: 'en_savoir_plus', text: 'En savoir plus' },
      ],
    }
  },
  watch: {
    id(val) {
      axios
        .get(`https://app2.oscar-black.com/api/design/${val}`)
        .then(response => {
          let i = 0
          this.format = response.data.format
          this.ctaButton = response.data.cta
          this.name = response.data.name

          if (this.format === 'carrousel') {
            // eslint-disable-next-line operator-assignment
            for (i = 0; i < response.data.visuels.length; i = i + 1) {
              this.items.push({
                id: response.data.visuels[i].id,
                adTitle: response.data.visuels[i].title,
                adDescription: response.data.visuels[i].description,
                adPricing: response.data.visuels[i].pricing,
                adText: response.data.visuels[i].text,
                redirectionUrl: response.data.visuels[i].redirection,
                file: '',
              })
            }
            // eslint-disable-next-line brace-style
          }
          else {
            this.price = response.data.visuels[0].pricing
            this.redirectionUrl = response.data.visuels[0].redirection
            this.adText = response.data.visuels[0].text
            this.adTitle = response.data.visuels[0].title
            this.adDescription = response.data.visuels[0].description
          }
        })
        .catch(() => {

        })
    },
  },
  methods: {
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
    formSubmitted() {
      const formData = new FormData()
      if (this.format === 'carrousel') {
        formData.append('file', this.file)
        formData.append('design_name', this.name)
        formData.append('design_format', this.format)
        formData.append('design_cta', this.ctaButton)
        formData.append('design_items', JSON.stringify(this.items))
      } else {
        formData.append('file', this.file)
        formData.append('design_name', this.name)
        formData.append('design_format', this.format)
        formData.append('design_cta', this.ctaButton)
        formData.append('design_items', JSON.stringify([{
          id: 1,
          adTitle: this.adTitle,
          adDescription: this.adDescription,
          adPricing: this.price,
          adText: this.adText,
          redirectionUrl: this.redirectionUrl,
          file: this.file,
        }]))
      }

      axios
        .post('https://app2.oscar-black.com/api/design', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          this.$emit('designss', response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Design created!',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'CrossIcon',
              variant: 'danger',
            },
          })
        })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
